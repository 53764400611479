:root {
  --offsets-services-list-item: 1;
}

.services {
  gap: var(--gap);
  padding: var(--gap) 0;
}

.services-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  list-style: none;
  padding: 0;
}

.services-list-item {
  display: flex;
  gap: var(--gap);
  flex-wrap: wrap;
}

.services-list-item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: var(--gap);
  flex-grow: 1;
  overflow: hidden;
  width: calc((100% - (var(--gap) * var(--offsets-services-list-item))) / (var(--offsets-services-list-item) + 1));
}

.services-list-item-card-image {
  width: 100%;
  height: auto;
}

.services-list-item-card-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: var(--gap);
}

.services-list-item-card-text {
  max-width: 350px;
  /*padding: 0;*/
}

.border {
  border: 1px solid var(--background-litegray);
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

