.service-item {
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  border-radius: var(--border-radius);
}

.service-header {
  display: flex;
  gap: var(--gap);
}

.service-item-info-text {
  text-align: var(--text-align);
}

.service-list-image {
  border-radius: var(--border-radius);
}