.product {
  gap: var(--gap);
  padding-bottom: var(--gap);
}

.product-page {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 var(--card-padding);
  justify-content: space-around;
  gap: var(--gap);
}

.product-page-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--gap);
  width: 30%;
}

/*.product-page-image-container {*/
/*  display: flex;*/
/*  border-radius: var(--border-radius);*/
/*  border: 1px solid lightgray;*/
/*  box-shadow: 0 0 10px rgba(0,0,0,0.2);*/
/*  padding: calc(var(--gap)/2);*/
/*}*/

.product-page-images {
  display: flex;
  row-gap: var(--gap);
  column-gap: 4%;
  flex-direction: row;
  flex-wrap: wrap;
}

.ant-image-mask {
  border-radius: var(--border-radius);
}

.product-page-main-image {
  border: 1px solid lightgray;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  border-radius: var(--border-radius);
  object-fit: contain;
}

.product-page-other-image {
  border-radius: var(--border-radius);
  object-fit: contain;
}

.product-page-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--gap);
  padding: var(--gap);
  width: 70%;
  align-content: center;
}

.product-page-right-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap);
  padding: var(--gap);
  align-content: center;
}

/*.product-page-right-bottom {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: flex-start;*/
/*  align-items: flex-start;*/
/*  gap: var(--gap);*/
/*  padding: var(--gap);*/
/*  align-content: center;*/
/*}*/

.product-page-title, .product-page-info-title {
  align-self: flex-start;
  font-weight: bold;
  color: var(--background-darkgray);
}

.product-page-button-price-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  /*align-items: center;*/
}

.product-page-similar-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.product-page-price {
  display: flex;
  position: relative;
  justify-content: space-around;
  font-size: 18px;
  font-weight: bold;
  margin-right: auto;
}

/*.product-page-price:after {*/
/*  content: '\20BD';*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: -15px;*/
/*  !*color: var(--red);*!*/
/*}*/

.product-page-info {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  width: 90%;
  align-self: center;
}

.product-page-info div {
  text-align: var(--text-align);
  /*text-indent: var(--gap);*/
  /*padding: var(--gap) 0;*/
}

.product-page-info p {
  line-height: 24px;
}

.product-page-info table {
  margin-bottom: calc(var(--gap));
}

.product-page-info br {
  content: "";
  display: block;
  margin-top: var(--gap);
}

