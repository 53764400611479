.ant-menu {
  /*justify-content: space-between !important;*/
 /*width: 100% !important;*/
}

/*.ant-menu-submenu-title {*/
/*  min-width: 230px !important;*/
/*  height: auto !important;*/
/*  min-height: 40px !important;*/
/*  !*padding-left: 0 !important;*!*/
/*}*/

/*.ant-menu-title-content {*/
/*  display: flex !important;*/
/*  white-space: normal !important;*/
/*  !*word-break: break-word !important;*!*/
/*  line-height: 20px !important;*/

/*}*/