.slider-container {
  width: 100%;
  height: 284px;
}

.slide {
  display: flex;
  justify-content: space-between;
  gap: 1px;
  /*width: 100%;*/
  /*height: 100%;*/
}

/*.slide-image-container {*/
/*  height: 284px;*/
/*  lineHeight: 284px;*/
/*  overflow: hidden;*/
/*!*  width: calc((100% - 2px) / 3);*!*/
/*}*/