* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

:root {
  --background: rgba(255, 255, 255, 1);
  --background-litegray: rgba(0, 0, 0, .1);
  --background-darkgray: rgba(0, 0, 0, .5);
  --text: rgba(0, 0, 0, 1);
  --text-secondary: rgba(109, 110, 114, 1);/*#6d6e72*/
  --red: rgba(200, 32, 32, 1);/*#c82020*/
  --red-secondary: rgba(200, 32, 32, .8);/*#d34d4d*/
  /*--red-secondary: rgba(200, 32, 32, .5);!*#e49090*!*/
  --red-light: rgba(200, 32, 32, .2);/*#f4d2d2*/
  /*--outline: 1px solid var(--red);*/
  --outline: none;
  --gap: 25px;
  /*--border-radius: 8px;*/
  --border-radius: 0px;
  --text-align: justify;
}

html {
  overflow-y: scroll;
}

body {
  /*display: flex;*/
  /*font-family: "Open Sans", sans-serif;*/
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 18px;
  line-height: 21px;
  color: var(--text);
  min-width: 320px;
  min-height: 100vh;
  margin: 0;
  /*background: url(./images/background.png) repeat scroll 5px 5px transparent;*/
  background-color: var(--background);
}

main {
  /*padding-top: var(--gap);*/
  padding-bottom: var(--gap);
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1190px;
  margin: 0 auto;
  padding-left: var(--gap);
  padding-right: var(--gap);
  outline: var(--outline);
}

.page-header {
  font-size: 18px;
  color: var(--red);
}

a {
  color: var(--text);
  text-decoration: none;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1, h2 {
  color: var(--red);
  font-size: 18px;
  line-height: 21px;
}

p {

}

ul, ol {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.my-list {
  padding-left: 20px; /* Расстояние от маркера до текста */
  background-image: url("./images/square.svg");
  background-repeat: no-repeat;
  background-size: 9px 9px;
  background-position: left center;
}

.d-none {
  display: none;
}

.red {
  color: var(--red);
}

.ant-image-preview-operations {
  display: none;
}

@media (max-width: 1140px) {

  .slider-container {
    height: 259px;
  }

}

@media (max-width: 1090px) {

  .slider-container {
    height: 247px;
  }

}

@media (max-width: 1040px) {

  .slider-container {
    height: 247px;
  }

}

@media (max-width: 1024px) {

  :root {
    --gap: 20px;
    --offsets: 2;
    --offsets-contacts-grid: 0;
  }

  .full-width-slider-container {
    display: block;
    width: 100%;
  }

  .short-width-slider-container {
    display: none;
  }

  .header-address-full {
    display: none;
  }

  .header-address-short {
    display: flex;
  }

  .about-info {
    padding: var(--gap) var(--gap) 0 var(--gap);
  }

  .slider-container {
    height: calc(((100vw - 2px) / 3 ) / 4 * 3);
  }

}

@media (max-width: 950px) {

  .header-top-right {
    display: flex;
  }

  .header-contacts {
    display: none;
  }

  .product-page-left {
    width: 40%;
  }

  .product-page-right {
    width: 60%;
  }

}

@media (max-width: 768px) {

  :root {
    --gap: 10px;
    --offsets: 1;
    --offsets-services-list-item: 0;
    --text-align: left;
  }

  .header_nav {
    justify-content: space-between;
    gap: 10px;
  }

  .services-list-item-card {
    padding: calc(var(--gap)*2);
    width: 100%;
    height: auto;
    align-items: center;
  }

  .image-container {
    padding-bottom: 0;
  }

  .info-container {
    padding-top: 0;
  }

  .services-list-item-card-image {
    width: 100%;
    height: auto;
    align-self: center;
  }

  .product-page-left {
    justify-content: center;
  }

}

@media (max-width: 590px) {

  .header-top {
    padding: 7px 7px 0px 7px;
  }

  .header-top-right-burger {
    display: block;
  }

  .header-bottom {
    display: none;
  }

  .slide-image-in-full {
    display: none;
  }

  .service-item-info {
    flex-direction: column;
  }

  .service-item-info-image-container {
    display: none;
  }

  .service-item-info-text {
    padding: var(--gap);
  }

  .product-page {
    padding: 0;
  }

  .product-page-left {
    width: 50%;
    padding: 0;
  }

  .product-page-image-container {
    padding: var(--gap);
  }

  .product-page-right {
    width: 50%;
    padding: 0;
  }

  .product-page-info {
    width: 95%;
  }

}

@media (max-width: 490px) {

  :root {
    --offsets: 0;
    --offsets-contacts-list: 0;
  }

  .contacts-list-item {
    min-height: 170px;
  }

  .product {
    padding-top: calc(var(--gap)*2);
    padding-bottom: calc(var(--gap)*2);
  }

  .cart-raw-actions {
    flex-direction: column;
  }

  .cart-raw-name {
    display: none;
  }
}


@media (max-width: 425px) {

  .services-list-item-card {
    padding: calc(var(--gap));
    width: 100%;
    height: auto;
    align-items: center;
  }

  .image-container {
    padding-bottom: 0;
  }

  .info-container {
    padding-top: 0;
  }

  .product-page-left {
    /*align-items: center;*/
  }

  .product-page-button-price-container {
    flex-direction: column;
    gap: var(--gap);
  }

}

@media (max-width: 320px) {

  .slider-container {
    height: 100px;
  }

}