.advantages {
  gap: var(--gap);
  padding: var(--gap) 0;
}

.advantages-info {
  display: flex;
  flex-direction: column;
  /*margin: 0 auto;*/
  gap: var(--gap);
  list-style: none;
}

.checked {
  min-height: 30px;
  align-content: center;
  padding-left: 40px;
  background-image: url("/src/images/checked.svg");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 30px 30px;
}