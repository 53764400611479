.header {
  /*position: relative;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 12px;
  line-height: 24px;
  outline: var(--outline);
  /*border-bottom: 2px solid var(--red);*/
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 25px 7px 0px 7px;
}

.header_logo {
  height: 80px;
  width: auto;
}

.header-top-right {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}

.header-top-right-burger {
  display: none;
}

.header-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 54px;
  /*padding-top: 5px;*/
}

.header_nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  flex-grow: 1;
  /*width: 100%;*/
}

.header_nav_item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  /*flex-grow: 1;*/
  /*height: 100%;*/
  padding: 5px;
  /*transition: background-color .15s;*/
  transition: color .3s;
  opacity: .8;
}

.header_nav_item:hover {
  opacity: 1;
  /*color: var(--text-secondary);*/
  /*background-color: var(--background-litegray);*/
  /*background: linear-gradient(to bottom, var(--background-litegray), white 70%, var(--background-litegray));*/
}

.header_nav_item:focus {
  opacity: 1;
  /*color: var(--text-secondary);*/
  /*background-color: var(--background-litegray);*/
  /*background: linear-gradient(to bottom, var(--background-litegray), white 70%, var(--background-litegray));*/
}

.header_nav_item:active {
  /*opacity: 1;*/
  color: var(--red);
  /*background-color: var(--background-darkgray);*/
  /*background: linear-gradient(to bottom, var(--background-darkgray), white 70%, var(--background-darkgray));*/
}

.header_nav_item.active {
  /*opacity: 1;*/
  color: var(--red);
  /*background-color: var(--background-darkgray);*/
  /*background: linear-gradient(to bottom, var(--background-darkgray), white 70%, var(--background-darkgray));*/
}

.cart-container {
  position: relative;
}

.cart-count {
  position: absolute;
  align-content: center;
  text-align: center;
  width: 20px;
  height: 20px;
  top: -9px;
  right: -11px;
  border: 2px solid var(--red);
  border-radius: 100%;
  font-size: 10px;
  line-height: 10px;

}

.header-contacts {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  flex-grow: 1;
  font-size: 13px;
  line-height: 24px;
}

.header-address-full, .header-address-short {
  background-image: url("/src/images/place.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 15px 15px;
  color: var(--text-secondary);
  padding: 5px 5px 5px 20px;
  opacity: .8;
}

.header-address-short {
  display: none;
}

.header-phone {
  background-image: url("/src/images/phone.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 15px 15px;
  color: var(--text-secondary);
  padding: 5px 5px 5px 20px;
  opacity: .8;
}

.header-address:hover, .header-phone:hover {
  opacity: 1;
 }

.header-address:focus, .header-phone:focus {
  opacity: 1;
}

.header-address:active, .header-phone:active {
  opacity: 1;
}