:root {
  --offsets-contacts-grid: 1;
  --offsets-contacts-list: 1;
}

.contacts {
  gap: var(--gap);
  padding: var(--gap) 0;
}

.grid {
  display: flex;
  gap: var(--gap);
  flex-wrap: wrap;
  padding: 0;
}

.grid-item {
  display: flex;
  width: calc((100% - (var(--gap) * var(--offsets-contacts-grid))) / (var(--offsets-contacts-grid) + 1));
  min-height: 500px;
  flex-wrap: wrap;
  gap: var(--gap);
  border-radius: var(--border-radius);
}

.grid-left {
  flex-direction: column;
  border: 1px solid var(--background-litegray);
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  padding: var(--gap);
}

.map-container {
  display: flex;
  flex-grow: 1;
  border: none;
  width: 100%;
}

.grid-right {
  flex-direction: column;
}

.contacts-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  list-style: none;
  padding: 0;
  width: 100%;
  height: 100%;
}

.contacts-list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: calc((100% - (var(--gap) * var(--offsets-contacts-list))) / (var(--offsets-contacts-list) + 1));
  padding: var(--gap);
  border: 1px solid var(--background-litegray);
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  border-radius: var(--border-radius);
}

.contacts-list-item::after {
  content: "";
  position: absolute;
  right: var(--gap);
  top: 0px;
  width: 40px;
  height: 40px;
  background-image: url("/src/images/bookmark.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  opacity: .8;
}

.contacts-list-item-info {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contacts-phones-list {
  list-style: none;
  padding: 0;
}

.contacts-phones-list-item:not(:last-child) {
  margin-bottom: 10px;
}

.contacts-list-social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(var(--gap)/3);
}

.social-image {
  width: 50px;
  height: 50px;
}

.link {
  opacity: .8;
}

.link:hover {
  opacity: 1;
}

.link:focus {
  opacity: 1;
}

.link:active {
  opacity: 1;
}