.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  outline: var(--outline);
  border-top: 2px solid var(--red);
  min-height: 50px;
}

.copyrights {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  line-height: 24px;
  margin: 0 auto;
}

.social {
  display: flex;
  gap: 24px;
}

.social-item {
  opacity: .8;
}

.social-item:hover {
  opacity: 1;
}

.footer_logo {
  max-height: 120px;
  width: auto;
}