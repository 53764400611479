:root {
  --card-padding: 18px;
}

.product-card {
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  width: calc((100% - (var(--gap) * var(--offsets))) / (var(--offsets) + 1));
  min-width: 220px;
  min-height: 300px;
  /*padding: var(--card-padding);*/
  border-radius: var(--border-radius);
  border: 1px solid lightgray;
  /*background-color: #f5f5f5;*/
  word-wrap: break-word;
  gap: 12px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  overflow: hidden;
}

.product-card a {
  padding: var(--card-padding);
  width: 100%;
  height: 100%;
}

.product-card__image {
  /*border-radius: 10px;*/
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.product-card__price {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  margin-right: 25px;
  text-align: left;
}

.price-discounted {
  color: limegreen;
}

/*.product-card__price:after {*/
/*  content: '\20BD';*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: -15px;*/
/*}*/

.product-card__discount {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  text-decoration: line-through;
}

.product-card__title {
  font-size: 14px;
  /*font-weight: bold;*/
}

@media (max-width: 768px) {
  :root {
    --card-padding: 15px;
  }
}

@media (max-width: 425px) {
  :root {
    --card-padding: 12px;
  }
}