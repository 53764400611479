:root {
  --offsets: 3;
}

.product-list {
  display: flex;
  align-self: start;
  flex-wrap: wrap;
  list-style: none;
  gap: var(--gap);
  padding: 0;
}

/*@media (max-width: 1024px) {*/
/*  :root {*/

/*  }*/
/*}*/

/*@media (max-width: 768px) {*/
/*  :root {*/

/*  }*/
/*}*/

/*@media (max-width: 425px) {*/
/*  :root {*/

/*  }*/
/*}*/

