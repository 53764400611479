.cart {
  gap: var(--gap);
  padding: var(--gap) 0;
}

.cart-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}

.cart-list-item {
  display: flex;
  align-items: center;
  gap: var(--gap);
}

.cart-link:hover {
  color: var(--text);
}

.cart-item-count {
  /*display: flex;*/
  /*width: 20px;*/
  /*justify-content: center;*/
}

.cart-raw-actions {
  display: flex;
  justify-content: flex-end;
  /*flex-direction: column;*/
}

.form-inputs {
  padding-top: 20px;
}