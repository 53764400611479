.about {
  display: flex;
  flex-direction: column;
  /*gap: var(--gap);*/
  padding-bottom: var(--gap);
}

.full-width-slider-container {
  display: none;
}

.about-header {
  margin-bottom: 5px;
}

.about-text {
  fontSize: 18px;
  line-height: 21px;
}

.mb25 {
  margin-bottom: 25px;
}

.red {
  color: var(--red);
}

.about-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 35px 80px 0 35px;
}

.about-info p {
  text-align: var(--text-align);
}