.catalog {
  gap: var(--gap);
  padding: var(--gap) 0;
}

.data {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}

.next-btn {
  color: var(--red);
  font-weight: bold;
  margin: 0 auto;
  border: none;
  background-color: transparent;
  cursor: pointer;
  opacity: .8;
}

.next-btn:hover {
  opacity: 1;
}

.next-btn:focus {
  opacity: 1;
}

.next-btn:active {
  opacity: 1;
}

